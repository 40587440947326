<template>
  <main-wrapper tool-background-image="linear-gradient(135deg, #C3BEF0 0%, #DEFCF9 100%)" border-left-color="#C3BEF0" :tool="false">
    <template slot="tool">
      <span class="sub-title ipx-1">首页</span>
    </template>
    <template>

      <div class="container" style="margin-top: 8px;">
        <div class="row">
          <div class="column column-2 ipa-3" style="">
            <vcl-code v-if="false" class="ima-4" :width="400" :height="150" color="#E1F5FE"></vcl-code>
            <div class="q-flex">
              <div class="left">
                <h2 class="q-title">常用功能</h2>

                <ul class="q-ul">
                  <li @click="() => $router.push('/online/monitor')"><span>在线监控</span></li>
                  <li @click="() => $router.push('/online/track')"><span>轨迹回放</span></li>
                  <li @click="() => $router.push('/risk/place')"><span>找常停点</span></li>
                  <li @click="() => $router.push('/report/one')"><span>统计报表</span></li>
                </ul>
              </div>
              <div class="right">
                <h2 class="q-title" title="仅统计有上线记录的有线设备">设备在线率</h2>

                <div class="q-demo" ref="container">
                  <vcl-code v-if="false" class="ima-4" style="margin:0;" :width="400" :height="150" color="#E1F5FE"></vcl-code>
                  <chart3 :items="onlineRate"></chart3>
                </div>
              </div>
            </div>
          </div>
          <div class="column column-1 column--nobk">
            <div class="container container--inline">
              <div class="row row--small">
                <div class="column column-1 center">
                  <div class="q-link" @click="() => $router.push('/manage/vehicle')">
                    <p class="link-count">
                      <animated-integar :value="total.totalVehicle"></animated-integar>
                    </p>
                    <p class="link-name">总车辆</p>
                  </div>
                </div>
                <div class="column column-1 center">
                  <div class="q-link" @click="() => $router.push('/manage/hold')">
                    <p class="link-count">
                      <animated-integar :value="total.totalUser"></animated-integar>
                    </p>
                    <p class="link-name">总用户</p>
                  </div>
                </div>
              </div>
              <div class="row row--small">
                <div class="column column-1 center">
                  <div class="q-link" @click="() => $router.push('/online/monitor')">
                    <p class="link-count">
                      <animated-integar :value="total.totalOnline"></animated-integar>
                    </p>
                    <p class="link-name">在线设备</p>
                  </div>
                </div>
                <div class="column column-1 center">
                  <div class="q-link" @click="() => $router.push('/risk/place')">
                    <p class="link-count">
                      <animated-integar :value="total.totalPolygon"></animated-integar>
                    </p>
                    <p class="link-name">风控区域</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <vcl-code class="ima-4" :width="300" :height="150"></vcl-code> -->
          </div>
        </div>
        <div class="row">
          <div class="column column-2 column--nobk">
            <div class="container container--inline">
              <div class="row">
                <div class="column column-1">
                  <vcl-code v-if="false" class="ima-4" :width="300" :height="150"></vcl-code>
                  <div class="q-wrapper">
                    <div class="q-title">报警信息</div>
                    <!-- <p class="q-info"><span class="dot"></span>低电量报警 <span class="count">100</span></p>
                    <p class="q-info"><span class="dot"></span>拆机报警 <span class="count">10</span></p>
                    <p class="q-info"><span class="dot"></span>剪线报警 <span class="count">20</span></p>
                    <p class="q-info"><span class="dot"></span>区域报警 <span class="count">50</span></p> -->
                    <p v-for="(item, index) in alarmList" :key="index" class="q-info" @click="() => $router.push('/online/monitor')">
                      <span class="dot"></span>
                      <span class="name">{{item.name}} </span>
                      <span class="count">{{item.count}}</span>
                    </p>
                  </div>
                </div>
                <div class="column column-1">
                  <vcl-code v-if="false" class="ima-4" :width="300" :height="150"></vcl-code>
                  <div class="q-wrapper">
                    <div class="q-title">续费信息</div>
                    <p class="q-info"><i-icon name="icon-Checkbox_Selected-copy"></i-icon> 无待续费车辆</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column column-1">
            <vcl-code v-if="false" class="ima-4" :width="300" :height="150"></vcl-code>
            <el-carousel height="260px" class="q-carousel" :autoplay="false">
              <el-carousel-item key="1">
                <div class="q-wrapper">
                  <div class="q-title">最新90天新增设备</div>
                  <chart2 ref="chart1" :items="oneYear" prefix="新增设备" suffix="台"></chart2>
                </div>
              </el-carousel-item>
              <el-carousel-item key="2">
                <div class="q-wrapper">
                  <div class="q-title">最近3年新增设备</div>
                  <chart2 ref="chart2" :items="threeYear" prefix="新增设备" suffix="台"></chart2>
                </div>
              </el-carousel-item>
              <el-carousel-item key="3">
                <div class="q-wrapper">
                  <div class="q-title">SIM卡过期数量</div>
                  <vcl-code v-if="false" class="ima-4" style="margin:0;" :width="350" :height="150" color="#E1F5FE"></vcl-code>
                  <chart1 ref="chart3" :items="simList"></chart1>
                </div>
              </el-carousel-item>
              <el-carousel-item key="4">
                <div class="q-wrapper">
                  <div class="q-title">无线设备离线状态</div>
                  <vcl-code v-if="false" class="ima-4" style="margin:0;" :width="350" :height="150" color="#E1F5FE"></vcl-code>
                  <chart1 ref="chart4" :items="offlineList"></chart1>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </template>
  </main-wrapper>
</template>

<script>
import AnimatedIntegar from '@/components/animated-integar'
import { VclCode } from 'vue-content-loading'
import Chart1 from './charts_new/chart1'
import Chart2 from './charts_new/chart2'
import Chart3 from './charts_new/chart3'
import * as API from '@/api/home'
export default {
  name: 'home',
  components: {
    AnimatedIntegar,
    VclCode,
    Chart1,
    Chart2,
    Chart3
  },
  data() {
    return {
      total: {
        totalUser: 0,
        totalVehicle: 0,
        totalOnline: 0,
        totalPolygon: 0
      },
      onlineRate: [],
      alarmList: [],
      oneYear: [],
      threeYear: [],
      simList: [],
      offlineList: []
    }
  },
  mounted() {
    this.fetchTotal()
    this.fetchOnlineRate()
    this.fetchAlarmList()
    this.fetchOneYear()
    this.fetchThreeYear()
    this.fetchSim()
    this.fetchOffline()
  },
  methods: {
    doLayout() {
      this.$refs.chart1.doLayout()
      this.$refs.chart2.doLayout()
      this.$refs.chart3.doLayout()
      this.$refs.chart4.doLayout()
      //this.$refs.chart2.doLayout()
    },
    fetchTotal() {
      API.GetHome_Total().then(ret => {
        this.total = Object.assign(this.total, ret.data)
      })
    },
    fetchOnlineRate() {
      API.GetHome_OnlineRate().then(ret => {
        this.onlineRate = ret.data.map(k => {
          return {
            count: k.count,
            item: k.name
          }
        })
      })
    },
    fetchAlarmList() {
      API.GetHome_Alarm().then(ret => {
        this.alarmList = ret.data
      })
    },
    fetchOneYear() {
      API.GetHome_OneYear().then(ret => {
        this.oneYear = ret.data.map(k => {
          return {
            year: k.name,
            name: k.name,
            index: k.index,
            sales: k.count
          }
        }).splice(0, 3)
      })
    },
    fetchThreeYear() {
      API.GetHome_ThreeYear().then(ret => {
        this.threeYear = ret.data.map(k => {
          return {
            year: k.name,
            name: k.name,
            index: k.index,
            sales: k.count
          }
        }).splice(0, 3)
      })
    },
    fetchSim() {
      API.GetHome_Sim().then(ret => {
        this.simList = ret.data.map(k => {
          return {
            count: k.count,
            item: k.name
          }
        })
      })
    },
    fetchOffline() {
      API.GetHome_Offline().then(ret => {
        this.offlineList = ret.data.map(k => {
          return {
            count: k.count,
            item: k.name.replace('离线', '')
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$high-light-color: #7497F1;
$primary-light-color: #E1F5FE;
$high-text-color: #514C76;
.container {
  $margin-normal: 8px;
  padding: $margin-normal;
  &.container--inline {
    padding: $margin-normal 0;
  }
  .row {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 260px;
    margin-left: -$margin-normal/2;
    margin-right: -$margin-normal/2;
    margin-top: $margin-normal;
    margin-bottom: $margin-normal;

    &.row--small {
      height: 126px;
    }

    .column {
      flex-grow: 1;
      flex-shrink: 1;
      margin: 0 $margin-normal/2;
      &:not(.column--nobk) {
        background-color: #F4F4F8;
      }
      .center {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;

        &:hover {
          background-color: #F1BC74;
          color: #FFF;
          .q-link .link-count {
            color: #FFF;
          }
          .q-link .link-name {
            color: #FFF;
          }
        }
      }
    }
    .column-2 {
      width: 66.66%;
    }
    .column-1 {
      width: 33.33%;
    }
  }
  .row:first-child {
    margin-top: -$margin-normal;
  }
  .row:last-child {
    margin-bottom: -$margin-normal;
  }
}
.q-link {
  text-align: center;
  user-select: none;
  .link-count {
    color: $high-text-color;
    font-size: 32px;
    font-weight: 600;
    font-family: 'Trebuchet MS', Helvetica, sans-serif;
  }
  .link-name {
    color: $high-text-color;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Trebuchet MS', Helvetica, sans-serif;
  }
}
@mixin q-title {
  color: $high-text-color;
  font-family: "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  font-weight: 500;
  font-size: 18px;
  user-select: none;
}
.q-flex {
  display: flex;
  padding: 5px 10px;
  font-family: "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  .q-title {
    color: $high-text-color;
    // font-family: STXihei, "华文细黑", "Microsoft YaHei", "微软雅黑";
    @include q-title;
  }
  .left {
    width: 200px;
    .q-ul {
      font-size: 15px;
      margin-top: 30px;
      line-height: 34px;
      list-style: none;
      color: $high-text-color;
      li span:hover {
        cursor: pointer;
        color: #F4511E;
      }
    }
  }
  .right {
    flex-grow: 1;
    .q-demo {
      overflow: hidden;
      height: 180px;
      margin-top: 15px;
    }
  }
}

.q-wrapper {
  padding: 25px 30px;
  .q-title {
    @include q-title;
    color: $high-text-color;
    font-family: 'Trebuchet MS', Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;

    & + .q-info {
      margin-top: 20px;
    }
  }
  .q-info {
    font-size: 14px;
    line-height: 32px;
    &:hover .name, &:hover.count {
      cursor: pointer;
      color: #F57C00;
    }
    .dot {
      width: 14px;
      height: 14px;
      background-color: $high-text-color;
      display: inline-block;
      margin-right: 10px;
      border-radius: 50%;
      vertical-align: middle;
    }

    .icon {
      font-size: 16px;
      vertical-align: middle;
    }

    .count {
      color: $high-text-color;
      font-weight: 600;
      font-family: 'Trebuchet MS', Helvetica, sans-serif;
    }
  }
}
.q-carousel {
  & ::v-deep .el-carousel__button {
    background-color: $high-text-color;
  }
}
</style>
